<template>
  <b-modal
    id="package-file-upload"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="Upload Payment Slip "
  >
    <b-overlay
      id="overlay-background"
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="uploadfileForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(handleFormSubmit)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                class="required"
                label="Select Payment Slip"
                label-cols-md="4"
                label-for="h-cName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-file"
                  vid="h-c-file"
                  rules="required"
                >
                  <b-form-file
                    id="file"
                    v-model="file"
                    accept="image/jpeg, image/png, image/jpg, application/pdf"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col
              offset-md="4"
            >
              <div class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  type="submit"
                  variant="primary"
                  @click="handleSubmit(handleFormSubmit)"
                >
                  Submit
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  // BTable,
  BOverlay,
  VBModal,
  BFormFile,
  BButton,
  BFormGroup,
  BCol,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SubscriptionRepository = RepositoryFactory.get('subscription')

function initialState() {
  return {
    open: false,
    invoiceId: null,
    loading: false,
    file: null,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    // BTable,
    BOverlay,
    BFormFile,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal() {
      await Object.assign(this.$data, initialState())
      this.open = true
      // this.getInvoiceDetails()
    },
    async handleFormSubmit() {
      this.loading = true
      try {
        const formData = new FormData()
        const fileInput = document.getElementById('file')
        const file = fileInput.files[0]
        const invoiceId = localStorage.getItem('subscriptionId')
        formData.append('file', file)
        formData.append('invoice_id', invoiceId)
        const res = await SubscriptionRepository.saveInvoiceImage(formData)
        if (res.status === 200) {
          this.showSuccessMessage(res.data.message)
          this.$router.push('/package-settings/subscription-billing')
        } else {
          this.showErrorMessage(res.data.errors)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    // async getInvoiceDetails() {
    //   try {
    //     const { data } = (await SubscriptionRepository.getBillingList())
    //     this.invoiceId = data.data.id
    //   } catch (e) {
    //     this.convertAndNotifyError(e)
    //   }
    // },
  },
}
</script>
<style scoped>
</style>
