<template>
  <div>
    <b-row>
      <b-col
        md="12"
        class="d-flex justify-content-center align-items-center"
      >
        <b-card>
          <b-row>
            <b-col
              lg="3"
              sm="12"
              class="border-right d-flex justify-content-center align-items-center"
            >
              <div>
                <h4
                  class="font-weight-bolder text-center mb-1"
                  style="font-size:1.5rem;"
                >
                  Start Date
                </h4>
                <h5 class="text-center mb-1">
                  {{ __dateTimeFormatter(subscriptionDetails.start_date) }}
                </h5>
                <h4
                  class="font-weight-bolder text-center mb-1"
                  style="font-size:1.5rem;"
                >
                  End Date
                </h4>
                <h5 class="text-center mb-1">
                  {{ __dateTimeFormatter(subscriptionDetails.end_date) }}
                </h5>
                <div class="mt-1 ml-1">
                  <table>
                    <tr
                      class="font-weight-bolder"
                      style="font-size:1.5rem;"
                    >
                      <td class="pr-1">
                        <div>
                          Total Payment :
                        </div>
                      </td>
                      <td>
                        $ {{ subscriptionDetails.total_amount }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </b-col>
            <b-col
              lg="9"
              md="12"
            >
              <!-- <b-row class="border-bottom">
                <div class="mx-1">
                  <h4>
                    Pay via PayHere
                  </h4>
                  <div class="d-flex mt-1">
                    <span>
                      <b-button
                        variant="success"
                        class="mr-2"
                        @click="checkout"
                      >
                        Pay Now
                      </b-button>
                    </span>
                    <img
                      src="https://www.payhere.lk/downloads/images/payhere_long_banner.png"
                      alt="PayHere"
                      width="440"
                    >
                  </div>
                  <div class="mt-1 mb-1">
                    NOTE: After clicking the "Pay Now" button, you will be directed to the payment page which you can enter the card details.
                  </div>
                </div>
              </b-row> -->
              <b-row>
                <div class="mx-1">
                  <h4 class="mt-1">
                    Offline Payment
                  </h4>
                  <span class="mt-1">
                    <b-button
                      variant="success"
                      class="mr-2"
                      @click="onClickUploadModal"
                    >
                      Paid Offline
                    </b-button>
                  </span>
                  <div class="mt-1">
                    NOTE: After clicking this, your package will be approved by Admin and will remain as pending activation until your bank payment is verified. Please make sure to transfer the payment to the following bank details. <br>
                    <div class="bg-info bg-lighten-4 p-1 mt-1">
                      0003 1002 8134 <br>
                      Sampath Bank, Nugegoda Branch. <br>
                      Swift Code: BSAMLKLX003
                    </div>
                  </div>
                  <div class="bg-danger bg-lighten-4 p-1 mt-1">
                    <small>
                      Notification will be sent to admin for approval.
                    </small>
                  </div>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <file-upload-modal ref="fileUploadModal" />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BButton,
} from 'bootstrap-vue'
import {
  Payhere, AccountCategory, Customer, CurrencyType, PayhereCheckout, CheckoutParams,
} from 'payhere-js-sdk'
import ErrorMessage from '@core/mixins/ErrorMessage'
import md5 from 'crypto-js/md5'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FileUploadModal from '@/components/subscription/FileUploadModal.vue'

const subscriptionRepository = RepositoryFactory.get('subscription')
const BusinessSettingsRepository = RepositoryFactory.get('businessSetting')

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BButton,
    FileUploadModal,
  },
  mixins: [ErrorMessage],
  data() {
    return {
      subscriptionId: null,
      subscriptionDetails: {},
      packageDetails: {},
      businessSettings: {},
    }
  },
  async mounted() {
    this.subscriptionId = Number(this.$route.params.id)
    this.fetchBusinessSettings()
    await this.getSubscriptionDetails()
    localStorage.setItem('subscriptionId', this.subscriptionId.toString())
  },
  methods: {
    async fetchBusinessSettings() {
      try {
        this.loading = true
        this.businessSetting = localStorage.getItem('businessSettings') ? JSON.parse(localStorage.getItem('businessSettings')) : (await BusinessSettingsRepository.getbusinessSettingsResource()).data.data
        if (!localStorage.getItem('businessSettings')) localStorage.setItem('businessSettings', JSON.stringify(this.businessSetting))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getSubscriptionDetails() {
      try {
        this.loading = true
        const { data } = (await subscriptionRepository.getSubscriptionById(this.subscriptionId)).data
        this.subscriptionDetails = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    onClickUploadModal() {
      this.$refs.fileUploadModal.openModal()
    },
    generateHash() {
      const merchantSecret = 'Mjg1MzUyMTA1NjExMDQ0NjU5MzczODk2NDM4NjYwMzUwODA1MzE2OQ=='
      const merchantId = '1222658'
      const orderId = '1'
      const amount = 50
      const hashedSecret = md5(merchantSecret).toString().toUpperCase()
      const amountFormatted = parseFloat(amount).toLocaleString('en-us', { minimumFractionDigits: 2 }).replaceAll(',', '')
      const currency = this.businessSetting.company_currency
      const hash = md5(merchantId + orderId + amountFormatted + currency + hashedSecret).toString().toUpperCase()

      return hash
    },
    async checkout() {
      const generatedHash = this.generateHash()
      Payhere.init('1222658', AccountCategory.SANDBOX)

      const customer = new Customer({
        first_name: this.businessSetting.company_name,
        last_name: 'Delivery',
        phone: this.businessSetting.company_hotline,
        email: this.businessSetting.company_finance_email,
        address: this.businessSetting.company_address,
        city: 'Colombo',
        country: 'Sri Lanka',
      })

      const checkoutData = new CheckoutParams({
        returnUrl: `http://localhost:8080/package-settings/subscribe/${this.subscriptionId}`,
        cancelUrl: `http://localhost:8080/package-settings/subscribe/${this.subscriptionId}`,
        notifyUrl: 'http://localhost:8080/notify',
        order_id: '1',
        itemTitle: 'example payment',
        currency: CurrencyType.USD,
        amount: Number('50' || '0.1'),
        hash: generatedHash,
      })
      const checkout = new PayhereCheckout(customer, checkoutData, () => {
        this.showErrorMessage('Checkout failed')
      })
      checkout.start()
    },
  },
}
</script>
